export const config = {
    appId: '4a91aff0-77d5-4b9b-9b13-4d5bff59bd8b',
    // redirectUrl: 'http://localhost:3000',
    redirectUrl: 'https://dashboard.mediaplus.nl/app',
    // redirectUrl: 'https://dashboard.mediaplus.nl/app-dev',
    ssmUrl: '/actions/insights/amazon/ssm',
    ssmParamName: 'endpoint=/dashboard_application/main/netherlands',
    // ssmParamName: 'endpoint=/dashboard_application/main/netherlands/dev',
    apiKey: 'MdaAbJPkyk6ph1Wadasd2HJdLCE7pBKDczkb9r9YCx84dU',
    scopes: [
        'user.read'
    ],
    authority: "https://login.microsoftonline.com/mediaxplainnl.onmicrosoft.com"
}
