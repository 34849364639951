import React, {useEffect, useState} from "react";
import {config} from "./Config";
import {AppBar, Box, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import axios from "axios";
import {Login, Providers, ProviderState} from '@microsoft/mgt-react';
import {getSsmParam, changeDashboard} from "./aws-service";
import Sidebar from "./sidebar";
import {Dashboard, SsmParam} from "./interfaces";
import PowerBiDashboard from "./power-bi-dashboard";
import { createTheme, ThemeProvider } from '@mui/material/styles';

function App() {
    const [isSignedIn] = useIsSignedIn();
    const [userIds, setUserIds] = useState<string[]>([]);
    const [genericDashboardId, setGenericDashboardId] = useState('');
    const [ssmParam, setSsmParam] = useState<SsmParam>();
    const [powerBiAccessToken, setPowerBiAccessToken] = useState('');
    const [showSidebar, setShowSidebar] = useState<boolean>(false);
    const [dashboard, setDashboard] = useState<Dashboard>();

    useEffect(() => {
        if (isSignedIn) {
            initialiseValues();
        }
    }, [isSignedIn]);

    useEffect(() => {
        if (userIds) {
            getSsmParam(setSsmParam, userIds)
        }
    }, [userIds]);

   useEffect(() => {
       if (ssmParam && ssmParam.dashboards.length > 0) {
           setGenericDashboardId(ssmParam.genericDashboardId);
           changeDashboard(ssmParam.dashboards[0].id, userIds, setDashboard)
       }
   }, [ssmParam]);

    async function initialiseValues() {
        getAccessToken();
        getUserIds(setUserIds)
    }

    function getAccessToken() {
        const apiKey = { 'X-Api-Key': config.apiKey };
        axios.post('/actions/insights/azzure/token', undefined,{
            headers: apiKey
        })
            .then(res => {
                const data = JSON.parse(res.data);
                setPowerBiAccessToken(data.access_token);
            })
            .catch(err => {
                console.log(err)
            });
    }

    async function getUserIds(setUserIds: (userIds: string[]) => void) {
        try {
            const azureGroups = await Providers.globalProvider.graph.client.api('me/memberOf/microsoft.graph.group').get();
            let ids: string[] = []
            azureGroups.value.map((group: any) => ids.push(group['id']))
            setUserIds(ids)
        } catch (e: any) {
            console.log("Can't get groups of the user: " + e)
        }
    }
    const theme = createTheme({
        palette: {
            secondary: {
                main: '#F6F6F6',
            },
        },
    });


    return (
        <Box sx={{ flexGrow: 1 }}>
            <ThemeProvider theme={theme}>
                <AppBar sx={{ boxShadow: 0 }} color='secondary' position="static">
                    <Toolbar>
                        {ssmParam && ssmParam.dashboards.length > 1 && <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={() => setShowSidebar(!showSidebar)}>
                            <MenuIcon />
                        </IconButton>}
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Dashboards
                        </Typography>
                        <Login/>
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
            {showSidebar && <Sidebar showSidebar={showSidebar}
                                     setShowSidebar={setShowSidebar}
                                     setShowDashboard={setDashboard}
                                     userIds={userIds}
                                     dashboardParameters={ssmParam ? ssmParam : {genericDashboardId: '', dashboards:[]}}
            />}
            {dashboard &&
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    <PowerBiDashboard dashboard={dashboard} powerBiAccessToken={powerBiAccessToken}
                                      genericDashboardId={genericDashboardId}/>
                </div>
            }
        </Box>
    );
}

function useIsSignedIn(): [boolean] {
    const [isSignedIn, setIsSignedIn] = useState(false);

    useEffect(() => {
        const updateState = () => {
            const provider = Providers.globalProvider;
            setIsSignedIn(provider && provider.state === ProviderState.SignedIn);
        };

        Providers.onProviderUpdated(updateState);
        updateState();

        return () => {
            Providers.removeProviderUpdatedListener(updateState);
        }
    }, []);

    return [isSignedIn];
}

export default App;