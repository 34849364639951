import axios from "axios";
import { config } from "./Config";
import { SsmParam, Dashboard } from "./interfaces";

const apiKey = {'X-Api-Key': config.apiKey};
const genericDashoardId = '/dashboard_application/dashboards/generic'
const genericDashoardDevId = '/dashboard_application/dashboards/generic/dev'

export function getSsmParam(setSsmParam: (ssmParam: any) => void, userIds: string[]) {
    let ssmParam: SsmParam = {
        genericDashboardId: "",
        dashboards: []
    }
    axios.post(config.ssmUrl, config.ssmParamName, {
        headers: apiKey})
        .then((res) => {
            ssmParam.genericDashboardId = res.data.genericDashboardId
            for (let i in res.data.dashboards) {
                for (let j in userIds) {
                    if (res.data.dashboards[i].groupIds.includes(userIds[j])) {
                        ssmParam.dashboards.push(res.data.dashboards[i])
                    }
                }
            }
            setSsmParam(ssmParam)
        })
        .catch(err => {
            console.log(err)
        })
}

export function changeDashboard(ssmParamId: string, userIds: string[],
                             setShowDashboard: (showDashboard: Dashboard) => void) {
    if (ssmParamId === genericDashoardId) {
        getGenericDashboardParamId(genericDashoardId, ssmParamId, userIds, setShowDashboard)
    } 
    else if (ssmParamId === genericDashoardDevId) {
        getGenericDashboardParamId(genericDashoardDevId, ssmParamId, userIds, setShowDashboard)
    }
    else {
        axios.post(config.ssmUrl, 'endpoint=' + ssmParamId, {
            headers: apiKey
        })
            .then((res) => {
                for (let i in res.data.groups) {
                    if (userIds.includes(res.data.groups[i].id)) {
                        let dashboardParam: Dashboard = res.data
                        dashboardParam.group = res.data.groups[i]
                        setShowDashboard(dashboardParam)
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export function getGenericDashboardParamId(genericId: string, ssmParamId: string, userIds: string[],
                                           setShowDashboard: (showDashboard: Dashboard) => void) {
    axios.post(config.ssmUrl, 'endpoint=' + ssmParamId, {
        headers: apiKey
    })
        .then((res) => {
            userIds.map((userId) => {
                if (res.data[userId]) {
                    changeDashboard(genericId+'/'+res.data[userId], userIds, setShowDashboard)
                }
            })
        })
        .catch(err => {
            console.log(err)
        })
}