import React, {useState, useEffect} from "react";
import { Button, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import {PowerBIEmbed} from "powerbi-client-react";
import {models} from "powerbi-client";
import {Dashboard} from './interfaces';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import loadingIcon from './serviceplan3.gif';

interface tabs {
    pages: Object,
    clickedIndex: number
}

export interface PowerBiDashboardProps {
    powerBiAccessToken: string,
    dashboard: Dashboard,
    genericDashboardId: string
}
let report: any

export default function PowerBiDashboard(props: PowerBiDashboardProps) {
    const [pagesGeneircDashboard, setPagesGeneircDashboard] = useState<tabs>({pages: {}, clickedIndex: 0});
    const [loading, setLoading] = useState<boolean>(true);
    const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(true);
    const [filters, setFilters] = useState<any[]>([]);

    useEffect(() => {
        setShowLoadingIcon(true)
        getPagesGenericDashboard()
        getFilters()
        showFirstPage()
    }, [props.dashboard])


    function showFirstPage() {
        if (props.dashboard.reportId === props.genericDashboardId) {
            const pages = props.dashboard.group?.pages;
            changePage(Object.keys(pages)[0], pages, 0)
        }
    }

    async function changePage(page: string, pages: any, index: number) {
        try {
            setPagesGeneircDashboard({pages: pages, clickedIndex: index})
            await report.setPage(pages[page]);
        } catch (errors) {
            console.log('ID: ' + pages[page]);
            console.log(errors);
        }
    }

    async function getPagesGenericDashboard() {
        if (props.dashboard.reportId === props.genericDashboardId) {
            const pages = props.dashboard.group?.pages
            setPagesGeneircDashboard({pages: pages, clickedIndex: 0})
        } else {
            setPagesGeneircDashboard({pages: {}, clickedIndex: 0})
        }
    }
    
    async function getFilters() {
        setLoading(true)
        setFilters(props.dashboard.group.filters?.map(filter => ({
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
                table: filter.filterTable,
                column: filter.filterColumn,
            },
            operator: "In",
            values: filter.filterValue,
            filterType: 1,
        })))
        setLoading(false)
    }
    const theme = createTheme({
        palette: {
            secondary: {
                main: '#F6F6F6',
            },
            primary: {
                main: '#b3b3b3',
            },
        },
    });

    async function printPage() {
        // Trigger the print dialog for your browser.
        try {
            await report.print();
        }
        catch (errors) {
            console.log(errors);
        }
    }

    const className = () => {
        const standardClassName = "Embed-container"
        if (props.dashboard.cssClassName) {
            return standardClassName + '-' + props.dashboard.cssClassName
        } else {
            return standardClassName
        }
    }

    return (
        <div>
            <h2 style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                {props.dashboard.name}
            </h2>
            {/*{showLoadingIcon &&*/}
            {/*    <img src={loadingIcon} className="loading-container"></img>*/}
            {/*}*/}
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                {pagesGeneircDashboard.pages &&
                    Object.keys(pagesGeneircDashboard.pages).map((pageName, index) => {
                        return <ThemeProvider theme={theme}>
                            <Button sx={{ boxShadow: 0 }} color={index === pagesGeneircDashboard.clickedIndex ?
                                'primary' : 'secondary'} style={{marginRight: '12px'}} variant="contained"
                                    onClick={() => changePage(pageName, pagesGeneircDashboard.pages, index)}>
                            {pageName}
                            </Button>
                        </ThemeProvider>
                    })}
                {props.dashboard.reportId === props.genericDashboardId && <IconButton
                        size="large"
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => printPage()}>
                        <DownloadIcon />
                    </IconButton>}
            </div>
            {!loading && <PowerBIEmbed
                cssClassName = { className() }

                embedConfig = {{
                    type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                    id: props.dashboard.reportId,
                    embedUrl: props.dashboard.embedUrl,
                    accessToken: props.powerBiAccessToken,
                    tokenType: models.TokenType.Aad,
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false
                            },
                            pageNavigation:{
                                visible: props.dashboard.showPaginatedNavigation
                            },
                        },
                        background: models.BackgroundType.Default,
                    },
                    filters: filters
                }}

                eventHandlers = {
                    new Map([
                        ['loaded', function () {console.log('Report loaded');
                        }],
                        ['rendered', function () {console.log('Report rendered');
                            setShowLoadingIcon(false);
                        }],
                        ['error', function (event: any) {console.log(event.detail);}]
                    ])
                }

                getEmbeddedComponent = { (embeddedReport) => 
                    // @ts-ignore
                    report = embeddedReport as Report
                }
            />}
        </div>
    )
}