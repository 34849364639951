import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {Dashboard, SsmParam} from './interfaces';
import { changeDashboard } from './aws-service';

interface SidebarProps {
    showSidebar: boolean;
    setShowSidebar: (show: boolean) => void;
    setShowDashboard: (dashboard: Dashboard) => void;
    userIds: string[];
    dashboardParameters: SsmParam;
}

export default function Sidebar(props: SidebarProps) {
    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }
                props.setShowSidebar(open);
            };

    function openDashboard(parameterStoreId: string) {
        changeDashboard(parameterStoreId, props.userIds, props.setShowDashboard)
    }

    return (
        <div>
            <React.Fragment>
                <SwipeableDrawer
                    anchor={'left'}
                    open={props.showSidebar}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <Box
                        sx={{ width: 250 }}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer( false)}
                    >
                        <List>
                            {props.dashboardParameters.dashboards.map((dashboardParameters: Dashboard) => (
                                <ListItem button key={dashboardParameters.name}>
                                    <ListItemText primary={dashboardParameters.name} onClick={() => 
                                        openDashboard(dashboardParameters.id)} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}
